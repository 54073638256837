<template>
  <div class="tw-w-full">
    <div class="tw-mt-4 tw-flex tw-flex-wrap tw-mb-6 tw--mx-2">
      <div class="tw-w-full md:tw-w-1/2">
        <div
          class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden"
        >
          <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
            <h2 class="tw-mb-4 tw-text-gray-800">
              Personal Details
            </h2>

            <form class="tw-w-full" @submit.prevent="updateProfile">
              <div class="form-group">
                <div class="tw-w-full">
                  <label class="form-label" for="full_name">
                    Full Name <span class="required-field">&#42;</span>
                  </label>
                  <input
                    id="full_name"
                    v-model="form.full_name"
                    v-validate="'required'"
                    name="full_name"
                    data-vv-as="full name"
                    class="form-control"
                    data-cy="full-name"
                    autocomplete="off"
                    type="text"
                  />
                  <p
                    v-show="errors.has('full_name')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    {{ errors.first('full_name') }}
                  </p>
                </div>
              </div>
              <div class="form-group">
                <div class="tw-w-full">
                  <label class="form-label" for="email">
                    Email <span class="required-field">&#42;</span>
                  </label>
                  <input
                    id="email"
                    v-model="form.email"
                    v-validate="'required|email'"
                    name="email"
                    data-vv-as="email"
                    data-cy="email"
                    autocomplete="off"
                    class="form-control"
                    type="text"
                  />
                  <p
                    v-show="errors.has('email')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    The email field must be a valid email address
                  </p>
                  <div
                    v-if="form.verification_pending_email"
                    class="tw-w-full tw-bg-orange-200 tw-mt-1 tw-py-2 tw-px-3 tw-rounded-md tw-text-orange-500 tw-leading-snug"
                    data-cy="email-change-message"
                  >
                    To change your email address to
                    <span class="tw-italic">{{
                      form.verification_pending_email
                    }}</span
                    >, please click the link in the email we've sent to
                    <span class="tw-italic">{{ authUser.email }}</span
                    >.
                    <a
                      class="tw-cursor-pointer tw-font-semibold tw-text-orange-500 tw-underline"
                      data-cy="link-resend-verification"
                      @click="resendVerification"
                    >
                      <u>Resend</u>
                    </a>
                  </div>
                </div>
              </div>
              <div class="form-group tw-mb-0">
                <div class="tw-w-full">
                  <SpinnerButton
                    :disabled="!valid || loading"
                    :loading="loading"
                    :spinner-only="true"
                    type="submit"
                    data-cy="btn-save"
                  >
                    Save
                  </SpinnerButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <div
          class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden profile-card"
        >
          <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
            <h2 class="tw-mb-4 tw-text-gray-800">
              Profile Picture
            </h2>

            <AvatarCropper
              :upload-handler="uploadImage"
              trigger="#pick-avatar"
            />

            <div class="tw-w-full">
              <div
                class="tw-relative tw-border tw-rounded-lg tw-overflow-hidden"
                style="width: 280px; height: 280px;"
                @mouseover="hoveredOnPicture = true"
                @mouseleave="hoveredOnPicture = false"
              >
                <div class="tw-absolute tw-w-full tw-h-full">
                  <div
                    class="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-gray-200"
                  >
                    <div class="tw-flex tw-items-center tw-justify-center">
                      <img
                        v-if="authUser.avatar_url"
                        :src="authUser.avatar_url"
                        :alt="authUser.full_name"
                        class="tw-object-cover tw-block tw-w-full tw-h-full"
                        style="min-width: 280px; min-height: 280px;"
                      />
                      <SvgIcon
                        v-else
                        name="user-fill"
                        class="tw-opacity-25"
                        style="width: 216px; height: 216px;"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-show="hoveredOnPicture || !authUser.avatar_url"
                  class="tw-absolute tw-w-full tw-h-full"
                >
                  <div
                    class="tw-flex tw-items-center tw-justify-center tw-w-full tw-absolute tw-bottom-0 tw-inset-x-auto"
                  >
                    <button
                      id="pick-avatar"
                      class="btn tw-w-full tw-rounded-none tw-bg-white tw-text-gray-700"
                    >
                      <div
                        class="tw-flex tw-items-center tw-justify-center tw-py-2"
                      >
                        {{ authUser.avatar_url ? 'Change' : 'Upload' }}
                      </div>
                    </button>

                    <button
                      v-if="authUser.avatar_url"
                      class="btn tw-w-full tw-rounded-none btn-red"
                      @click="destroyImage"
                    >
                      <div
                        class="tw-flex tw-items-center tw-justify-center tw-py-2"
                      >
                        Remove
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <ChangePassword />
      </div>

      <div class="tw-w-full md:tw-w-1/2 tw-pb-4">
        <div
          class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-h-full tw-rounded-lg tw-overflow-hidden"
          :class="{ 'locale-card': emailReportScheduleLoading }"
        >
          <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
            <h2 class="tw-mb-4 tw-text-gray-800">
              Notification Settings
            </h2>

            <div class="tw-relative">
              <form v-show="!emailReportScheduleLoading" class="tw-w-full">
                <div class="form-group">
                  <div class="tw-w-full">
                    <ToggleButton
                      :value="sendEmailNotification"
                      :labels="true"
                      color="#1da1f2"
                      @change="updateSendEmailNotification"
                    />
                    <label class="tw-ml-2 tw-select-none magic-checkbox-label">
                      Receive email alerts for requests and approvals.
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <div class="tw-w-full">
                    <ToggleButton
                      :value="settings.enable_comment_email"
                      :labels="true"
                      color="#1da1f2"
                      @change="toggleEnableCommentEmail"
                    />
                    <label class="tw-ml-2 tw-select-none">
                      Receive email alerts for comments.
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <div class="tw-w-full">
                    <DailyEmailReportSchedule
                      v-if="activeCompany"
                      :report-company="activeCompany"
                      @loading="toggleDailyEmailReportScheduleLoading"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <div class="tw-w-full">
                    <WeeklyEmailReportSchedule
                      v-if="activeCompany"
                      :report-company="activeCompany"
                      @loading="toggleWeeklyEmailReportScheduleLoading"
                    />
                  </div>
                </div>

                <div class="form-group tw-mb-0">
                  <div class="tw-w-full">
                    <ToggleButton
                      v-if="!!authUser.slack_reports_count"
                      :value="sendSlackNotification"
                      :labels="true"
                      color="#1da1f2"
                      @change="updateSendSlackNotifications"
                    />
                    <label
                      :class="{ 'tw-ml-2': !!authUser.slack_reports_count }"
                      class="tw-select-none magic-checkbox-label"
                    >
                      Receive daily summary report in
                      <img
                        class="tw-inline-block"
                        src="./../assets/icons/slack-logo.svg"
                        style="width: auto; height: 28px;"
                      />
                      <a
                        :href="slackAppAuthUrl"
                        target="_blank"
                        class="btn-link tw-font-semibold"
                      >
                        Connect to Slack
                      </a>
                    </label>
                  </div>
                </div>
              </form>
              <Loading
                :blur="null"
                loader="dots"
                :is-full-page="false"
                :active="emailReportScheduleLoading"
                class="tw-mt-10"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <div
          class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden locale-card"
        >
          <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
            <h2 class="tw-mb-4 tw-text-gray-800">
              Date/Time Settings
            </h2>

            <form class="tw-w-full tw-mb-6">
              <div class="form-group tw-mb-0">
                <div class="tw-w-full">
                  <label class="form-label"
                    >Date Format
                    <span class="required-field">&#42;</span></label
                  >
                </div>
              </div>

              <div class="form-group tw-mb-3">
                <div class="tw-inline-block tw-mr-5">
                  <input
                    id="uk-format"
                    v-model="form.locale"
                    :value="userDefaultLocale"
                    class="magic-radio"
                    type="radio"
                    name="uk-format"
                    data-cy="uk-date-format"
                    @change="updateLocale"
                  />
                  <label class="magic-radio-label" for="uk-format"
                    >DD/MM/YYYY</label
                  >
                </div>

                <div class="tw-inline-block">
                  <input
                    id="us-format"
                    v-model="form.locale"
                    class="magic-radio"
                    type="radio"
                    name="us-format"
                    data-cy="us-date-format"
                    value="en_US"
                    @change="updateLocale"
                  />
                  <label class="magic-radio-label" for="us-format"
                    >MM/DD/YYYY</label
                  >
                </div>
              </div>
            </form>

            <form class="tw-w-full">
              <div class="form-group tw-mb-0">
                <div class="tw-w-full">
                  <label class="form-label"
                    >Time Format
                    <span class="required-field">&#42;</span></label
                  >
                </div>
              </div>

              <div class="form-group tw-mb-3">
                <div class="tw-inline-block tw-mr-5">
                  <input
                    id="24-hour"
                    :checked="settings.in_24_hour_clock"
                    value="true"
                    class="magic-radio"
                    type="radio"
                    name="24-hour"
                    data-cy="24-hour-clock"
                    @change="toggle24HourClock"
                  />
                  <label class="magic-radio-label" for="24-hour">24 Hour</label>
                </div>

                <div class="tw-inline-block">
                  <input
                    id="12-hour"
                    :checked="!settings.in_24_hour_clock"
                    value="false"
                    class="magic-radio"
                    type="radio"
                    name="12-hour"
                    data-cy="12-hour-clock"
                    @change="toggle24HourClock"
                  />
                  <label class="magic-radio-label" for="12-hour">12 Hour</label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/2 tw-pb-4">
        <PrivacySettings :user="authUser" />
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <div
          class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden display-card"
        >
          <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
            <h2 class="tw-mb-4 tw-text-gray-800">
              Display Settings
            </h2>

            <form class="tw-w-full">
              <div class="form-group">
                <div class="tw-w-full">
                  <ToggleButton
                    :value="settings.high_contrast_mode"
                    :labels="true"
                    color="#1da1f2"
                    @change="toggleHighContrastMode"
                  />
                  <label class="tw-ml-2 tw-select-none magic-checkbox-label">
                    Increase the colour contrast for easier viewing.
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <div
          class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden"
        >
          <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
            <h2 class="tw-mb-4 tw-text-gray-800">
              Delete Account
            </h2>

            <form class="tw-w-full">
              <div class="form-group tw-mb-0">
                <div class="tw-w-full tw-mb-3">
                  <SpinnerButton
                    type="button"
                    class="tw-border-red-500 btn-white hover:tw-bg-red-500 hover:tw-border-red-500 tw-text-red-500 hover:tw-text-white hover:shadow-lg"
                    data-cy="btn-delete-account"
                    @click="
                      () => {
                        $modal.show('delete-account')
                      }
                    "
                  >
                    Delete my Account
                  </SpinnerButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Modal
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="delete-account"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">Delete Account</p>
          </div>
          <div>
            <button class="modal-close" @click="$modal.hide('delete-account')">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form class="tw-w-full" @submit.prevent="deleteAccount">
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="tw-leading-normal tw-font-semibold tw-text-gray-800"
                >Deleting your account will permanently remove all the data
                associated with your profile and you will logged out from the
                system.
                <br />
                <br />
                It will not be possible to restore your account after performing
                this action.</label
              >
              <input
                id="delete-confirm"
                v-model="deleteKeyword"
                class="form-control tw-mt-3"
                type="text"
                autocomplete="off"
                name="delete-confirm"
                placeholder="Type word DELETE to confirm"
                data-cy="delete-confirm"
              />
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="isDisabled || isDeleting"
                  :loading="isDeleting"
                  loading-text="Deleting"
                  class="btn btn-red tw-border-red-500 hover:tw-border-red-700"
                  type="submit"
                  data-cy="submit-delete-account"
                >
                  Delete Account
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import 'blueimp-canvas-to-blob'
import AvatarCropper from '@/components/my-profile/AvatarCropper'
import ValidatesForm from '@/mixins/ValidatesForm'
import { ToggleButton } from 'vue-js-toggle-button'
import PrivacySettings from '@/components/my-profile/PrivacySettings'
import DailyEmailReportSchedule from '@/components/email-report-schedule/DailyEmailReportSchedule'
import WeeklyEmailReportSchedule from '@/components/email-report-schedule/WeeklyEmailReportSchedule'
import SpinnerButton from '@/components/SpinnerButton'
import ChangePassword from '@/components/my-profile/ChangePassword'
import Loading from 'vue-loading-overlay'
import Profile from '@/api/profile/Profile'

export default {
  name: 'MyProfile',

  middleware: 'auth',

  components: {
    DailyEmailReportSchedule,
    WeeklyEmailReportSchedule,
    ToggleButton,
    AvatarCropper,
    PrivacySettings,
    SpinnerButton,
    Loading,
    ChangePassword,
  },

  mixins: [ValidatesForm],

  data: () => ({
    image: null,
    loading: false,
    uploading: false,
    isDeleting: false,
    dailyEmailReportScheduleLoading: true,
    weeklyEmailReportScheduleLoading: true,
    form: {
      email: '',
      full_name: '',
      image: {},
      locale: '',
    },
    sendEmailNotification: false,
    sendSlackNotification: false,
    hoveredOnPicture: false,
    settings: {
      enable_comment_email: true,
      in_24_hour_clock: true,
      high_contrast_mode: false,
    },
    deleteKeyword: '',
  }),

  computed: {
    emailReportScheduleLoading() {
      return (
        this.dailyEmailReportScheduleLoading ||
        this.weeklyEmailReportScheduleLoading
      )
    },

    slackAppAuthUrl() {
      return process.env.VUE_APP_SLACK_APP_AUTH_URL
    },

    isDisabled() {
      return this.deleteKeyword !== 'DELETE'
    },

    userDefaultLocale() {
      const locale = this.form.locale

      if (locale === 'en_US') {
        return 'en_GB'
      }

      return locale
    },
  },

  created() {
    this.loadForm()

    this.sendEmailNotification = !this.authUser.disable_email
    this.settings = {
      enable_comment_email: !!this.authUser.settings.enable_comment_email,
      in_24_hour_clock: this.authUser.settings.in_24_hour_clock,
      high_contrast_mode: !!this.authUser.settings.high_contrast_mode,
    }
    this.sendSlackNotification = !!this.authUser.enabled_slack_reports_count
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    loadForm() {
      this.form = {
        ...this.authUser,
      }
    },

    async resendVerification() {
      try {
        await Profile.resendVerification()

        this.success('Email verification link has been sent.')
      } catch ({ response }) {
        this.error(response.data.message)

        await this.fetchUser()
        this.loadForm()
      }
    },

    toggleDailyEmailReportScheduleLoading(value) {
      this.dailyEmailReportScheduleLoading = value
    },

    toggleWeeklyEmailReportScheduleLoading(value) {
      this.weeklyEmailReportScheduleLoading = value
    },

    toggleEnableCommentEmail({ value }) {
      this.settings.enable_comment_email = value

      this.updateSettings()

      const status = value ? 'enabled' : 'disabled'
      this.success(`Email notifications for comments ${status} successfully.`)
    },

    toggle24HourClock(event) {
      const value = event.target.value === 'true'

      this.settings.in_24_hour_clock = value

      this.updateSettings()

      const clockType = value ? '24' : '12'
      this.success(`Changed to ${clockType} hour clock successfully.`)
    },

    uploadImage(cropper) {
      cropper
        .getCroppedCanvas({ width: 300, height: 300 })
        .toBlob(async blob => {
          try {
            await Profile.uploadAvatar(blob)

            this.fetchUser()

            this.success('Profile picture uploaded successfully.')
          } catch (e) {
            //
          }
        })
    },

    async destroyImage() {
      await Profile.deleteAvatar()

      this.fetchUser()

      this.success('Profile picture removed successfully.')
    },

    async updateProfile() {
      this.loading = true

      try {
        await this.validate()

        if (!this.valid) return

        await Profile.update(this.form)

        await this.fetchUser()

        this.loadForm()

        this.success('Profile information updated successfully.')
      } catch ({ response: { data, status } }) {
        if (status === 422) {
          this.validationErrors(data.errors)
        } else {
          this.error('We are having some issues updating profile.')
        }
      }

      this.loading = false
    },

    async updateSendEmailNotification({ value }) {
      this.loading = true

      let status
      if (value) {
        await Profile.enableEmailNotification()
        status = 'enabled'
      } else {
        await Profile.disableEmailNotification()
        status = 'disabled'
      }

      await this.fetchUser()

      this.success(`Email notifications ${status} successfully.`)

      this.loading = false
    },

    async toggleHighContrastMode({ value }) {
      this.loading = true

      this.settings.high_contrast_mode = value

      this.updateSettings()

      const status = value ? 'enabled' : 'disabled'
      this.success(`Increased colour contrast is ${status}.`)

      this.loading = false
    },

    async updateSettings() {
      this.loading = true

      await Profile.updateSettings({
        ...this.settings,
      })

      await this.fetchUser()

      this.loading = false
    },

    async updateSendSlackNotifications({ value }) {
      this.loading = true

      let status
      if (value) {
        await Profile.enableSlackNotifications()
        status = 'enabled'
      } else {
        await Profile.disableSlackNotifications()
        status = 'disabled'
      }

      await this.fetchUser()

      this.success(`Slack notifications ${status} successfully.`)

      this.loading = false
    },

    async updateLocale() {
      await Profile.updateLocale(this.form.locale)

      await this.fetchUser()

      this.success(`Date format changed successfully.`)
    },

    async deleteAccount() {
      this.isDeleting = true

      await Profile.deleteProfile()

      this.logout()
    },
  },
}
</script>

<style lang="scss" scoped>
@screen md {
  .profile-card {
    min-height: 386px;
  }

  .locale-card {
    min-height: 269px;
  }

  .display-card {
    min-height: 153.5px;
  }
}
</style>
