<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title">Company</h2>
          </div>
        </div>
      </div>

      <div>
        <form class="tw-w-full lg:tw--mx-2 tw-mt-4" @submit.prevent="submit">
          <div class="md:tw-flex">
            <div class="tw-w-full md:tw-w-1/3">
              <div class="form-group">
                <div class="tw-w-full lg:tw-px-2 xl:tw-w-2/3">
                  <label class="form-label" for="name">
                    Company Name <span class="required-field">&#42;</span>
                  </label>
                  <input
                    id="name"
                    v-model="company.name"
                    v-validate="'required'"
                    v-focus
                    name="name"
                    data-vv-as="company name"
                    data-cy="company-name"
                    class="form-control"
                    type="text"
                    autocomplete="off"
                  />
                  <p
                    v-show="errors.has('name')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    {{ errors.first('name') }}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <div class="tw-w-full lg:tw-px-2 xl:tw-w-2/3">
                  <label for="start_day" class="form-label">
                    First Day of Week <span class="required-field">&#42;</span>
                  </label>
                  <div class="tw-relative">
                    <VSelect
                      id="start_day"
                      v-model="selectedFirstDayOfWeek"
                      :options="weekDays"
                      :multiple="false"
                      :searchable="false"
                      :show-labels="false"
                      :allow-empty="false"
                      :max-height="180"
                      track-by="isoWeekday"
                      label="isoWeekdayString"
                      placeholder=""
                      data-cy="first-day-of-week"
                    >
                    </VSelect>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="tw-w-full lg:tw-px-2 xl:tw-w-2/3">
                  <label class="form-label">
                    Allowance Unit <span class="required-field">&#42;</span>
                  </label>
                </div>
                <div class="tw-w-full tw-px-3">
                  <div class="tw-inline-block tw-mr-4">
                    <input
                      id="allowance_unit_is_days"
                      v-model="company.allowance_unit_is_days"
                      :value="true"
                      class="magic-radio"
                      type="radio"
                      data-cy="allowance-unit"
                      name="allowance_unit_is_days"
                    />
                    <label
                      class="magic-radio-label"
                      for="allowance_unit_is_days"
                      >Days</label
                    >
                  </div>
                  <div class="tw-inline-block tw-mr-4">
                    <input
                      id="allowance_unit_in_hours"
                      v-model="company.allowance_unit_is_days"
                      :value="false"
                      class="magic-radio"
                      type="radio"
                      name="allowance_unit_is_days"
                    />
                    <label
                      class="magic-radio-label"
                      for="allowance_unit_in_hours"
                      >Hours</label
                    >
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="tw-w-full lg:tw-px-2 xl:tw-w-2/3">
                  <label class="form-label">
                    <div class="tw-flex tw-items-center">
                      <div>Hours Per Working Day</div>
                      <span class="required-field">&#42;</span>
                      <ExtraInfo icon="question">
                        <div class="tw-p-4 tw-w-48">
                          This is the number of hours which will be used when
                          the employee requests a full day of leave. If they
                          request a half day of leave then half of this value
                          will be used. You should set this value to your
                          company standard working hours, it is possible to have
                          exceptions for employees.
                          <div>
                            <a
                              class="btn-link tw-font-semibold"
                              target="_blank"
                              href="https://help.leavedates.com/hc/en-us/articles/360010569959-Setting-up-a-company-on-Leave-Dates"
                              >More info</a
                            >
                          </div>
                        </div>
                      </ExtraInfo>

                      <HoursPerWorkingDayWarning
                        :schedule-breakdowns="
                          company.active_working_schedule.schedule_breakdowns
                        "
                        :minutes-per-working-day="
                          company.minutes_per_working_day
                        "
                      />
                    </div>
                  </label>
                  <input
                    v-model="hoursPerWorkingDay"
                    v-validate="'required'"
                    class="form-control"
                    min="0"
                    max="24"
                    step="any"
                    name="minutes_per_working_day"
                    data-vv-as="hours per working day"
                    type="number"
                    inputmode="decimal"
                  />
                  <p
                    v-show="errors.has('minutes_per_working_day')"
                    class="tw-mt-1 tw-text-red-700 tw-text-sm"
                  >
                    The hours per working day must be a number between 1 and 24.
                  </p>
                </div>
              </div>

              <div v-if="hasSSOIntegration" class="form-group">
                <div class="tw-flex tw-items-center tw-leading-none tw-px-2">
                  <ToggleButton
                    v-model="company.sso_enforced"
                    :labels="true"
                    :sync="true"
                    color="#1da1f2"
                  />
                </div>
                <label class="form-label tw-mt-2">
                  Enforce SSO login
                </label>
              </div>

              <div class="form-group">
                <div class="tw-flex tw-items-center tw-leading-none tw-px-2">
                  <ToggleButton
                    id="allowance_accrual_toggle"
                    v-model="company.allowance_accrual_enabled"
                    :labels="true"
                    :sync="true"
                    color="#1da1f2"
                  />
                </div>
                <label class="form-label tw-mt-2">
                  Show accruals in leave requests
                </label>
              </div>
            </div>

            <div class="tw-w-full md:tw-w-2/3">
              <div class="working-week-wrap" data-cy="working-week-wrap">
                <div class="form-group">
                  <div class="tw-w-full tw-px-3">
                    <div class="tw-w-full tw-mb-4">
                      <label class="form-label">
                        Working Week <span class="required-field">&#42;</span>
                      </label>
                    </div>

                    <div class="tw-w-full">
                      <WorkingSchedule
                        v-if="company.id"
                        v-model="company.active_working_schedule"
                        @valid="scheduleValidate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group tw-mb-0">
            <div class="tw-w-full tw-px-2">
              <div class="tw-flex tw-justify-start">
                <SpinnerButton
                  :disabled="!valid || !scheduleValid || loading"
                  :loading="loading"
                  :spinner-only="true"
                  type="submit"
                  data-cy="btn-submit"
                >
                  Save
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { find, sortBy } from 'lodash-es'
import moment from 'moment-timezone'
import VSelect from 'vue-multiselect'
import ExtraInfo from '@/components/ExtraInfo'
import ValidatesForm from '@/mixins/ValidatesForm'
import HoursPerWorkingDayWarning from '@/components/working-schedule/HoursPerWorkingDayWarning'
import SpinnerButton from '@/components/SpinnerButton'
import { Company } from '@/api'
import { ToggleButton } from 'vue-js-toggle-button'
import AuthProviders from '@/api/company/AuthProviders'

const WorkingSchedule = () =>
  import('@/components/working-schedule/WorkingSchedule.vue')

export default {
  name: 'Company',

  components: {
    VSelect,
    ExtraInfo,
    WorkingSchedule,
    HoursPerWorkingDayWarning,
    SpinnerButton,
    ToggleButton,
  },

  mixins: [ValidatesForm],

  data: () => ({
    company: {
      id: null,
      name: '',
      first_day_of_week: '',
      allowance_unit_is_days: '',
      minutes_per_working_day: '',
      active_working_schedule: {},
      domain_name: '',
      sso_enforced: false,
      allowance_accrual_enabled: false,
    },
    loading: false,
    scheduleValid: true,
    hasSSOIntegration: false,
  }),

  computed: {
    weekDays() {
      const weekdays = moment.weekdays().map(function(weekday, index) {
        return {
          isoWeekday: index === 0 ? 7 : index,
          isoWeekdayString: weekday,
        }
      })

      return sortBy(weekdays, 'isoWeekday')
    },

    hoursPerWorkingDay: {
      get() {
        const minutes = this.company.minutes_per_working_day
        return minutes ? minutes / 60 : null
      },
      set(hours) {
        this.company.minutes_per_working_day = hours ? hours * 60 : 0
      },
    },

    selectedFirstDayOfWeek: {
      get() {
        return find(this.weekDays, {
          isoWeekday: this.company.first_day_of_week,
        })
      },
      set(val) {
        this.company.first_day_of_week = val.isoWeekday
      },
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.setupForm()

        this.getAuthProviders()
      },
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser', 'fetchCompany']),

    async submit() {
      await this.validate()

      if (!this.valid && this.scheduleValid) return

      this.loading = true

      try {
        await Company.update(this.activeCompany, this.company)

        await this.submitSchedule()

        this.fetchUser()

        this.fetchCompany(this.activeCompany)

        this.success('Company details saved successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    submitSchedule() {
      return this.$http.post(`company-working-schedules`, {
        company_id: this.company.id,
        schedule: this.company.active_working_schedule.schedule_breakdowns,
      })
    },

    setupForm() {
      this.company = {
        ...this.activeCompany,
      }
    },

    scheduleValidate(valid) {
      this.scheduleValid = valid
    },

    async getAuthProviders() {
      const { data } = await AuthProviders.get(this.activeCompany.id)

      this.hasSSOIntegration = data.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
@screen md {
  .working-week-wrap {
    width: 635px;
  }
}
</style>
